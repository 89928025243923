export class FormMode {

    public static EDIT: FormMode = new FormMode('EDIT');
    public static PREVIEW: FormMode = new FormMode('PREVIEW');
    public static CREATE: FormMode = new FormMode('CREATE');

    mode: string;

    constructor(mode) {
        this.mode = mode;
    }

    isEdit(): boolean {
        return this.mode === 'EDIT';
    }

    isPreview(): boolean {
        return this.mode === 'PREVIEW';
    }

    isCreate(): boolean {
        return this.mode === 'CREATE';
    }

}
