import Order from "@/models/Order";
import Infrastructure from "@/models/enums/Infrastructure";
import {Voivodeship} from "@/models/enums/Voivodeship";

export class CreateOrEditOrderRequest {

    infrastructure: string = null;
    systemAddress: string = null;
    candidatesCount: number | string = null;
    activeGrandSchools: boolean = false;
    automaticAccept: boolean = false;
    additionalRecruitment: boolean = false;
    voivodeship: string = null;
    implementerLogin: string;
    implementerEmail: string;
    releaseDate: string = null;
    isDeployProject: boolean = false;
    deployProjectManager: string = null;
    comment: string = null;

    constructor(order?: Order) {

        if (order) {
            this.infrastructure = (<Infrastructure>order.infrastructure).name;
            this.systemAddress = order.systemAddress.replace('.edu.com.pl', '');
            this.implementerLogin = order.implementerLogin;
            this.implementerEmail = order.implementerEmail;
            this.candidatesCount = order.candidatesCount;
            this.activeGrandSchools = order.activeGrandSchools;
            this.automaticAccept = order.automaticAccept;
            this.additionalRecruitment = order.additionalRecruitment;
            this.voivodeship = (<Voivodeship>order.voivodeship).name;
            this.releaseDate = order.releaseDate;
            this.isDeployProject = order.isDeployProject;
            this.deployProjectManager = order.deployProjectManager;
            this.comment = order.comment;
        }

    }


}
