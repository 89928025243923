






































































































































































































































































































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {orderService, profileService, sessionService, userService} from "@/services";
import Order from "@/models/Order";
import OrderStatus from "@/models/enums/OrderStatus";
import UserSession from "@/models/UserSession";
import {Role} from "@/models/enums/Role";
import {ActionItem, sortAlphabetically, sortDate, TableUtils} from "@/utils/table-utils";
import {EventBus} from "@/main";
import {Page} from "@/models/Page";
import {WatchOptions} from "vue";
import {SortAndFilterOptions} from "@/models/SortAndFilterOptions";
import {CreateOrEditOrderRequest} from "@/models/request/CreateOrEditOrderRequest";
import {
  emailExistRule,
  emailRule,
  integerNonZeroNumberRule,
  passwordRule,
  requiredRule,
  systemAddressClientRule,
  systemAddressExistRule,
  systemAddressRule,
  usernameExistRule,
  usernameRule,
  VForm
} from "@/utils/form-utils";
import {UserNotification} from "@/models/UserNotification";
import {UserNotificationType} from "@/models/UserNotificationType";
import Infrastructure from "@/models/enums/Infrastructure";
import Implementer from "@/models/Implementer";
import {Voivodeship} from "@/models/enums/Voivodeship";
import {User} from "@/models/User";
import {FormMode} from "@/models/enums/FormMode";

@Component({
  name: 'Orders',
  components: {}
})
export default class OrdersView extends Vue {

  $refs!: {
    createOrEditOrderForm: VForm,
    rejectedStatusConfirmationForm: VForm
  }

  actionItems: Array<ActionItem> = [
    new ActionItem('Modyfikuj', this.openEditOrderDialog, [Role.ADMIN, Role.IMPLEMENTER], [OrderStatus.DRAFT, OrderStatus.REJECTED]),
    new ActionItem('Złóż', this.changeOrderStatus.bind(this, OrderStatus.SENT), [Role.ADMIN, Role.IMPLEMENTER], [OrderStatus.DRAFT, OrderStatus.REJECTED]),
    new ActionItem('Wycofaj', this.changeOrderStatus.bind(this, OrderStatus.DRAFT), [Role.IMPLEMENTER], [OrderStatus.SENT]),
    new ActionItem('Odrzuć', this.openRejectedStatusConfirmDialog.bind(this, OrderStatus.REJECTED), [Role.ADMIN], [OrderStatus.SENT]),
    new ActionItem('Realizuj', this.changeOrderStatus.bind(this, OrderStatus.IN_PROGRESS), [Role.ADMIN], [OrderStatus.SENT]),
    new ActionItem('Zakończ', this.changeOrderStatus.bind(this, OrderStatus.END), [Role.ADMIN], [OrderStatus.IN_PROGRESS])
  ];

  orders: Page<Order> = new Page();
  loading: boolean = true;
  options: any = {};
  headers: Array<any> = [
    {text: 'ID', align: 'start', filterable: true, value: 'id', roles: [Role.ADMIN, Role.IMPLEMENTER]},
    {
      text: 'Adres systemu',
      align: 'start',
      filterable: true,
      value: 'systemAddress',
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    {
      text: 'Login wdrożeniowca',
      align: 'start',
      filterable: true,
      value: 'implementerLogin',
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    {
      text: 'Status',
      align: 'start',
      filterable: true,
      value: 'status.label',
      sort: sortAlphabetically,
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    {
      text: 'Data statusu',
      align: 'start',
      filterable: true,
      value: 'statusDate',
      sort: sortDate,
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    {
      text: 'Data utworzenia',
      align: 'start',
      filterable: true,
      value: 'timeCreated',
      sort: sortDate,
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    {
      text: 'Autor zamówienia',
      align: 'start',
      filterable: true,
      value: 'author.username',
      roles: [Role.ADMIN, Role.IMPLEMENTER]
    },
    {text: '', value: "action", roles: [Role.ADMIN, Role.IMPLEMENTER]}
  ];
  session: UserSession = null;
  isAdmin: boolean = false;

  helpers: any = {

    author: {
      toggle: false,
      title: 'Autor zamówienia',
      text: 'Login użytkownika systemu SWW, który utworzył zamówienie.'
    },
    infrastructure: {
      toggle: false,
      title: 'Infrastruktura (hosting)',
      text: 'Wybór miejsca hostowania systemu - czy na infrastrukturze Vulcan (przeważnie komercyjne), czy na infrastrukturze klienta (przeważnie projekt).\n' +
          '\n' +
          'Wybór Vulcan = adres systemu typu np.: warszawa.edu.com.pl\n' +
          '\n' +
          'Wybór klient = adres systemu typu np.: naborpp.edukacja.olsztyn.eu'
    },
    systemAddress: {
      toggle: false,
      title: 'Nazwa systemu',
      text: 'Człon występujący w adresie strony aplikacji przed ciągiem ".edu.com.pl". Np.: powiatkielecki.\n' +
          '\n' +
          'Powinien zostać uprzednio ustalony z klientem i przez niego zatwierdzony.\n' +
          '\n' +
          'Zmiana nazwy po oddaniu do użytku zrealizowanego zamówienia nie będzie możliwa.'
    },
    systemAddressClient: {
      toggle: false,
      title: 'Adres systemu',
      text: 'Adres strony aplikacji, np.: naborpp.eduportal.koszalin.pl.\n' +
          '\n' +
          'Powinien zostać uprzednio ustalony z klientem i przez niego zatwierdzony.\n' +
          '\n' +
          'Zmiana adresu po oddaniu do użytku zrealizowanego zamówienia nie będzie możliwa.'
    },
    implementer: {
      toggle: false,
      title: 'Wdrożeniowiec',
      text: 'Istniejący wdrożeniowiec w systemie. Musi być aktywny.'
    },
    implementerLogin: {
      toggle: false,
      title: 'Login wdrożeniowca',
      text: 'Login (nazwa użytkownika) osoby opiekującej się wdrożeniem i będącej dla Frati osobą kontaktową we wszelkich sprawach dotyczących danego systemu.\n' +
          '\n' +
          'Użytkownik będzie miał rolę Wdrożeniowiec w uruchomionym systemie.\n' +
          '\n' +
          'Zwyczajowo przyjmuje się login postaci: imie.nazwisko.'
    },
    implementerPassword: {
      toggle: false,
      title: 'Hasło wdrożeniowca',
      text: 'Inicjalne hasło dla osoby opiekujacej się wdrożeniem niezbędne do pierwszego zalogowania.\n' +
          '\n' +
          'Format: Minimum 8 znaków, w tym co najmniej: 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny.\n' +
          '\n' +
          'Wymagana identyczność z polem Powtórz hasło.'
    },
    implementerPasswordRepeat: {
      toggle: false,
      title: 'Powtórzone hasło',
      text: 'Inicjalne hasło dla osoby opiekujacej się wdrożeniem niezbędne do pierwszego zalogowania.\n' +
          '\n' +
          'Format: Minimum 8 znaków, w tym co najmniej: 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny.\n' +
          '\n' +
          'Wymagana identyczność z polem Hasło wdrożeniowca.'
    },
    implementerEmail: {
      toggle: false,
      title: 'Email wdrożeniowca',
      text: 'Adres email osoby opiekującej się wdrożeniem, dla której zostanie założone konto w aplikacji naborowej.\n' +
          '\n' +
          'Może posłużyć ewentualnej awaryjnej zmianie hasła w apliakcji naborowej.'
    },
    candidatesCount: {
      toggle: false,
      title: 'Liczba kandydatów',
      text: 'Szacunkowa spodziewana liczba kandydatów. Istotny parametr ze względu na planowanie infrastruktury serwerowej, zatem oczekiwana jest realna wartość - najprawdopodobniej uzyskana od klienta.'
    },
    activeGrandSchools: {
      toggle: false,
      title: 'Aktywne SP',
      text: 'Należy zaznaczyć, czy we wdrożeniu biorą udział aktywne szkoły podstawowe (SP), czy takich szkół w ogóle nie planuje się.'
    },
    automaticAccept: {
      toggle: false,
      title: 'Automatyczna akceptacja',
      text: 'Należy zaznaczyć, czy we wdrożeniu z aktywnymi szkołami podstawowymi obowiązuje automatyczna akceptacja osiągnięć na etapie przesyłania ich przez aktywne szkoły podstawowe.'
    },
    additionalRecruitment: {
      toggle: false,
      title: 'Elektroniczna RU',
      text: 'Należy zaznaczyć, czy jest planowane przeprowadzenie rekrutacji uzupełniającej (RU) w trybie elektronicznym, czyli powtórzenie całego procesu przy użyciu systemu.\n' +
          '\n' +
          'Gdy nie jest to znane w momencie składania zamówienia, można pozostawić bez wyboru.'
    },
    voivodeship: {
      toggle: false,
      title: 'Województwo',
      text: 'Należy wybrać województwo właściwe ze względu na zwierzchność kuratorium oświaty dla jednostek objętych wdrożeniem.'
    },
    releaseDate: {
      toggle: false,
      title: 'Data oddania klientowi',
      text: 'Planowana data przekazania klientowi do użytku skonfigurowanego systemu. Zazwyczaj tożsame z pierwszym przekazaniem kont dla użytkowników jednostek.'
    },
    isDeployProject: {
      toggle: false,
      title: 'Wdrożenie projektowe',
      text: 'Należy zaznaczyć, jeśli całe wdrożenie stanowi formalnie projekt.'
    },
    deployProjectManager: {
      toggle: false,
      title: 'Kierownik projektu',
      text: 'Imię i nazwisko kierownika projektu, w ramach którego wdrożenie jako całość funkcjonuje.'
    },
    comment: {
      toggle: false,
      title: 'Dodatkowe uwagi',
      text: 'Wszelkie uwagi dotyczące wdrożenia i warunków uruchomienia systemu nie dajace się objąć parametrami formularza.\n' +
          'Standardowo w systemach zeszłorocznych jest podtrzymywana oferta (struktura jednostek, oddziały, regulaminy szkół). Jeśli ma być inaczej, należy tutaj opisać odstępstwa od standardu.'
    },

  };

  implementers: Array<Implementer> = [];
  infrastructures: Array<Infrastructure> = [];
  voivodeships: Array<Voivodeship> = [];

  editMode: FormMode = FormMode.CREATE;
  editedOrderRef: Order = null;

  createOrEditOrderRequestReleaseDateMenu: boolean = false;
  createOrEditOrderFormValid: boolean = false;
  createOrEditOrderDialog: boolean = false;
  createOrEditOrderServerError: boolean = false;
  createOrEditOrderRequest: CreateOrEditOrderRequest = new CreateOrEditOrderRequest();
  createOrEditOrderRules = {

    systemAddressClientRules: [
      requiredRule(),
      systemAddressClientRule()
    ] as Array<Function>,

    systemAddressRules: [
      requiredRule(),
      systemAddressRule()
    ] as Array<Function>,

    infrastructure: [
      requiredRule()
    ] as Array<Function>,

    implementerLoginRules: [
      requiredRule()
    ] as Array<Function>,

    implementerEmailRules: [
      requiredRule(),
      emailRule()
    ] as Array<Function>,

    candidatesCountRules: [
      requiredRule(),
      integerNonZeroNumberRule()
    ] as Array<Function>,

    voivodeshipRules: [
      requiredRule()
    ] as Array<Function>,

    releaseDateRules: [
      requiredRule()
    ] as Array<Function>,

    deployProjectManagerRules: [
      requiredRule()
    ] as Array<Function>

  }

  apiErrorMessages: any = {
    createOrEditOrderRequest: {
      implementerLogin: [],
      implementerEmail: [],
      systemAddress: []
    }
  }

  profile: User = null;

  async created(): Promise<void> {
    EventBus.$on('sessionChange', this.getSession)
    await this.getSession();
    await this.getOrders();
    await this.getImplementers();
    this.profile = await profileService.getProfile();
    this.headers = TableUtils.hideColumnsForRoles(this.headers, this.session);
    this.infrastructures = Infrastructure.list();
    this.isAdmin = (<Role>this.session.role).is(Role.ADMIN);
    this.voivodeships = Voivodeship.list();
  }

  getStatusName(status: string): string {
    return OrderStatus.of(status).label;
  }

  @Watch('createOrEditOrderRequest', {deep: true} as WatchOptions)
  async createOrEditOrderRequestApiValidation(): Promise<void> {

    this.apiErrorMessages.createOrEditOrderRequest.implementerEmail = [];
    this.apiErrorMessages.createOrEditOrderRequest.implementerLogin = [];
    this.apiErrorMessages.createOrEditOrderRequest.systemAddress = [];

    let systemAddressForInfrastructure: string = this.createOrEditOrderRequest.systemAddress;
    if(this.createOrEditOrderRequest.infrastructure === Infrastructure.VULCAN.name){
      systemAddressForInfrastructure = `${systemAddressForInfrastructure}.edu.com.pl`;
    }

    if(this.editedOrderRef == null || (systemAddressForInfrastructure !== this.editedOrderRef.systemAddress)){

      let systemAddressExistsResult: string | boolean = await systemAddressExistRule()(systemAddressForInfrastructure);

      if (systemAddressExistsResult !== true) {
        this.apiErrorMessages.createOrEditOrderRequest.systemAddress.push(systemAddressExistsResult);
      }

    }

    if(!this.createOrEditOrderRequest.infrastructure){
      this.apiErrorMessages.createOrEditOrderRequest.systemAddress.push('Dokonaj wyboru infrastruktury');
    }

  }


  async getImplementers(): Promise<void> {
    this.implementers = await userService.listImplementers();
  }

  @Watch('options', {deep: true} as WatchOptions)
  async getOrders(): Promise<void> {

    //Skip when no options filled
    if (this.options['page'] === undefined) {
      return;
    }

    this.loading = true;
    let orders: Page<Order> = await orderService.list(SortAndFilterOptions.of(this.options));

    for (let order of orders.content) {
      order.actionItemsConditionsMet = {};

      let conditionsMet: number = 0;
      for (let actionItem of this.actionItems) {

        if (!actionItem.conditionsMet(this.session, order)) {
          order.actionItemsConditionsMet[actionItem.title] = false;
        } else {
          order.actionItemsConditionsMet[actionItem.title] = true;
          conditionsMet++;
        }

        order.noneOfConditionsMet = conditionsMet === 0;

      }

    }

    this.orders = orders;
    this.loading = false;

  }

  async getSession(): Promise<void> {
    this.session = await sessionService.getSession();
  }

  beforeDestroy(): void {
    EventBus.$off('sessionChange', this.getSession);
  }

  async showDetails(order: Order): Promise<void> {
    this.editMode = FormMode.PREVIEW;
    this.createOrEditOrderDialog = true;
    this.editedOrderRef = order;
    this.createOrEditOrderRequest = new CreateOrEditOrderRequest(order);
  }

  openCreateOrderDialog(): void {
    this.editMode = FormMode.CREATE;
    this.createOrEditOrderDialog = true;
    this.createOrEditOrderRequest = new CreateOrEditOrderRequest();

    if (!this.isAdmin) {
      this.createOrEditOrderRequest.implementerLogin = this.profile.username;
      this.createOrEditOrderRequest.implementerEmail = this.profile.email;
    }

    this.createEditConfirmationDialogActionFunction = this.createOrder;

  }

  openEditOrderDialog(order: Order): void {
    this.editMode = FormMode.EDIT;
    this.createOrEditOrderDialog = true;
    this.createOrEditOrderRequest = new CreateOrEditOrderRequest(order);
    this.createEditConfirmationDialogActionFunction = this.editOrder;
  }

  cancelCreateOrEditOrder(): void {
    this.createOrEditOrderDialog = false;
    this.createOrEditOrderRequest = new CreateOrEditOrderRequest();
  }

  postCreateEditOrder: Order = null;

  async createOrder(): Promise<void> {

    await this.$refs.createOrEditOrderForm.validate();

    if (this.createOrEditOrderFormValid) {

      if(!this.createEditConfirmationDialog){
        this.openCreateEditConfirmationDialog(this.createOrder);
      }else{
        this.createOrEditOrderDialog = false;
        this.postCreateEditOrder = await orderService.createOrder(this.createOrEditOrderRequest);
        this.createOrEditOrderRequest = new CreateOrEditOrderRequest();
        EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Utworzono zamówienie'));
        await this.getImplementers();
        await this.getOrders();
      }

    }

  }

  orderAction(order: Order, actionItem: ActionItem): void {
    this.editedOrderRef = order;
    actionItem.action(order);
  }

  async editOrder(): Promise<void> {

    await this.$refs.createOrEditOrderForm.validate();

    if (this.createOrEditOrderFormValid) {

      if(!this.createEditConfirmationDialog){
        this.openCreateEditConfirmationDialog(this.editOrder);
      }else {

        this.createOrEditOrderDialog = false;
        this.postCreateEditOrder = await orderService.updateOrder(this.editedOrderRef.id, this.createOrEditOrderRequest);
        this.createOrEditOrderRequest = new CreateOrEditOrderRequest();
        EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Zapisano zamówienie'));
        await this.getImplementers();
        await this.getOrders();
      }

    }

  }

  rejectedStatusConfirmationDialog: boolean = false;
  rejectedStatusConfirmationServerError: string = null;
  rejectedStatusConfirmationFormValid: boolean = false;
  rejectedStatusConfirmationReason: string = null;
  rejectedStatusConfirmationReasonRules: Array<Function> = [requiredRule()];

  async setRejectedStatusWithReason(): Promise<void> {

    await this.$refs.rejectedStatusConfirmationForm.validate();

    if (this.rejectedStatusConfirmationFormValid) {
      await orderService.updateOrderStatusWithReason(this.editedOrderRef.id, OrderStatus.REJECTED, this.rejectedStatusConfirmationReason);
      EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Status zmieniony'));
      await this.cancelRejectedStatusConfirmationDialog();
      await this.cancelCreateOrEditOrder();
      await this.getOrders();
    }

  }

  async cancelRejectedStatusConfirmationDialog(): Promise<void> {
    this.rejectedStatusConfirmationDialog = false;
    this.rejectedStatusConfirmationServerError = null;
    this.rejectedStatusConfirmationFormValid = false;
    this.rejectedStatusConfirmationReason = null;
  }

  async openRejectedStatusConfirmDialog(newStatus: OrderStatus, order: Order): Promise<void> {
    this.rejectedStatusConfirmationDialog = true;
  }

  async changeOrderStatus(newStatus: OrderStatus, order: Order, notification: boolean = true): Promise<void> {

    await orderService.updateOrderStatus(order.id, newStatus);

    if(notification){
      EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Status zmieniony'));
    }

    await this.getOrders();

    if (this.editMode.isPreview()) {
      this.cancelCreateOrEditOrder();
    }

  }

  createEditConfirmationDialog: boolean = false;
  createEditConfirmationDialogActionFunction: Function = null;

  async cancelCreateEditConfirmationDialog(): Promise<void> {
    await this.createEditConfirmationDialogActionFunction();
    this.createEditConfirmationDialog = false;
  }

  async createEditConfirmationDialogAction(): Promise<void> {
    await this.createEditConfirmationDialogActionFunction();
    await this.changeOrderStatus(OrderStatus.SENT, this.postCreateEditOrder, false);
    this.createEditConfirmationDialog = false;
  }

  openCreateEditConfirmationDialog(callback: Function): void {
    this.createEditConfirmationDialog = true;
    this.createEditConfirmationDialogActionFunction = callback;
  }


}


